import React from 'react';
import MainPage from './components/pages/MainPage';

function App() {
    return <div className="app">
        <MainPage/>
    </div>
}

export default App;
