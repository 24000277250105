import React from 'react'
import './style.css'
import {Alert} from 'react-bootstrap';

export default function Loader({ isLoading }) {

    return (isLoading &&
        <React.Fragment>
            <Alert>Пожалуйста, подождите! Проверка может занять несколько минут.</Alert>
            <div className="loading"/>
        </React.Fragment>
    )
}