import React, {useRef} from 'react';
import {Button, Form} from 'react-bootstrap';

export default function UploadFile({ stateUploadFile, setStateUploadFile, setStateResult, setIsLoading, setErrorMessage }) {

    const formRef = useRef(null);

    const readFiles = (files) => {
        const result = [];

        for (let i = 0; i < files.length; i++) {
            if (files[i]) {
                const reader = new FileReader();

                reader.onload = (event) => {
                    let text = event.target.result
                        .replace(/\n/g, '')
                        .replace(/\r/g, '')
                        .replace(',]', ']');

                    try {
                        result[i] = {name: files[i].name, arr: JSON.parse(text)};
                    } catch (e) {
                        console.error(e)
                        result[i] = {name: files[i].name, arr: undefined};
                        setErrorMessage({type: 'read_file', message: `Файл ${files[i].name} не будет обработан, так как содержит ошибку`
                    })
                    }
                };

                reader.onerror = (event) => {
                    console.error(reader.error);
                }

                reader.readAsText(files[i], 'utf8');
            }
        }

        setStateUploadFile(result);
    }

    const cnct = arr => {
        return arr.reduce((resultArr, item) => {
            return resultArr.concat(item);
        }, [])
    }

    const getResult = async (e) => {
        e.preventDefault();

        const result = [];
        const filesNameResult = [];

        if (stateUploadFile.length === 1 && stateUploadFile[0].length === 0) {
            setErrorMessage('Нет файлов для обработки');
        } else {
            setIsLoading(true);
            setErrorMessage('');
            setStateResult(null);

            const filesContent = stateUploadFile.filter(item => {return item.arr});

            for (const itemFile of filesContent) {
                const resultItem = await sendToDaData(itemFile.arr, itemFile.name);

                if (resultItem) {
                    const _resultItem = resultItem.filter(item => {
                        if (item.state) {
                            return item;
                        }
                    });

                    result.push(_resultItem);
                    filesNameResult.push(itemFile.name);
                }

            }

            setStateResult({ nameFiles: filesNameResult, arr: cnct(result) });
            setIsLoading(false);

        }
    }

    const sendToDaData = async (tins, fileName) => {
        const data = {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        };

        Object.assign(data, {body: JSON.stringify(tins)});

        try {
            const response = await (fetch('/api/v1/daData', data));
            const responseJSON = await response.json();
            if (!responseJSON.isSuccess) {
                throw new Error(responseJSON.error);
            } else {
                return responseJSON.result;
            }
        } catch (e) {
            console.error(e);
            setErrorMessage({type: 'send_file', message: `Файл ${fileName} не обработан из-за проблем с сервером, попробуйте обработать его отдельно`
            });

            return undefined;
        }

    }

    const cancel = event => {
        event.preventDefault();
        formRef.current.reset();
        setStateResult(null);
        setStateUploadFile([]);
        setErrorMessage('');
    }

    return <Form ref={formRef} onSubmit={(e) => getResult(e)}>
        <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Выберите файл для загрузки</Form.Label>
            <Form.Control type="file" accept=".csv,application/json" multiple={true} onChange={event => {
                setStateResult(null);
                setErrorMessage('');
                readFiles(event.target.files);
            }}/>
        </Form.Group>
        <Button variant="outline-primary" type="submit" disabled={!stateUploadFile}>Отправить</Button>
        {' '}
        <Button variant="outline-danger" onClick={cancel}>Сбросить</Button>
    </Form>
}
