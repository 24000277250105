import React, {useRef} from 'react';
import {Alert, Button, Container, Table} from 'react-bootstrap';
import './style.sass';

export default function ResultsTable({ stateResult }) {
    const tableRef = useRef(null);

    const saveToPDF = event => {
        event.preventDefault();
        window.print();
    }

    const formattedDate = date => {
        let day = date.getDate().toString();
        day = day.length === 1 ? `0${day}` : day;
        let month = (date.getMonth() + 1).toString();
        month = month.length === 1 ? `0${month}` : month
        return `${day}-${month}-${date.getFullYear()}`
    }

    const getStyleStatus = status => {
        let styleStatus = '';

        switch (status) {
            case 'LIQUIDATING': styleStatus = 'danger'; break;
            case 'LIQUIDATED': styleStatus = 'danger'; break;
            case 'BANKRUPT': styleStatus = 'danger'; break;
            case 'REORGANIZING': styleStatus = 'warning'; break;
            case 'ACTIVE': styleStatus = 'success'; break;
            default: styleStatus = ''
        }
        return styleStatus;
    }

    return <React.Fragment>
        {stateResult && stateResult.nameFiles.length > 0 &&
          <Alert variant='success'>Результат проверки файлов: {stateResult.nameFiles.join(', ')}
          </Alert>}
        {stateResult && stateResult.nameFiles.length > 0 && stateResult.arr.length > 0 &&
            <Container>
                <Table ref={tableRef} bordered>
                    <caption>Результаты проверки от {formattedDate(new Date())}</caption>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Предприятие</th>
                        <th>RID</th>
                        <th>ИНН</th>
                        <th>Статус</th>
                        <th>Дата последнего изменения статуса</th>
                    </tr>
                    </thead>
                    <tbody>
                    {stateResult.arr.map((item, index) => {
                        return <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.title}</td>
                            <td>{item.rid}</td>
                            <td>{item.tin}</td>
                            <td className={getStyleStatus(item.state.status)}>{item.state.status}</td>
                            <td>{item.state.hasOwnProperty('actuality_date') && formattedDate(new Date(item.state.actuality_date))}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
                <Button variant="outline-primary" onClick={saveToPDF}>Сохранить в PDF</Button>
            </Container>}
        {stateResult && stateResult.nameFiles.length > 0 && stateResult.arr.length === 0 &&
            <Alert>Проверка завершена!
                Все запрошенные предприятия находятся в статусе ACTIVE
            </Alert>}
    </React.Fragment>
}