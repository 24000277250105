import React, {useReducer, useState} from 'react';
import {Image, Col, Container, Row, Alert} from 'react-bootstrap';
import parse from 'html-react-parser';
import standardLogos from '../../../assets/images/standard_logos.png';
import UploadFile from '../../UploadFile';
import ResultsTable from '../../ResultsTable';
import Loader from '../../Loader';

function MainPage() {
    const styleLogos = {width: 240, height: 48};
    const [stateUploadFile, setStateUploadFile] = useState([]);
    const [stateResult, setStateResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useReducer(reduceError, '');

    function reduceError(state, action) {
        if (action.hasOwnProperty('type') && action.type === 'read_file') {
            return `${state} <p>${action.message}</p>`;
        } else if (action.hasOwnProperty('type') && action.type === 'send_file') {
            return `${state} <p>${action.message}</p>`;
        }
        else return action
    }

    return <div className="main-page">
        <Container>
            <Row>
                <Col className="a-left">
                    <Image style={styleLogos} src={standardLogos}/>
                </Col>
                <Col><h2>MobiCash TIN verification</h2></Col>
            </Row>
            <Row>
                <UploadFile stateUploadFile={stateUploadFile} setStateUploadFile={setStateUploadFile} setStateResult={setStateResult} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage}/>
            </Row>
            <Row>
                {errorMessage && <Alert variant='warning'>
                    {parse(errorMessage)}
                </Alert>}
                {<Loader isLoading={isLoading}/>}
                <ResultsTable stateResult={stateResult}/>
            </Row>
        </Container>
    </div>
}

export default MainPage;